<template>
  <div class="app auth-page" id="auth" :style="authStyle">
    <div class="container" style="padding-top: 120px">
      <loader v-if="loading"></loader>
    </div>
  </div>
</template>

<script>
import authMixin from '../../mixins/auth';

export default {
  mixins: [authMixin],
  data() {
    return {
      loading: true
    };
  },
  mounted() {
    const { id } = this.$route.query;
    if (!id) {
      this.$notify({
        type: 'error',
        message: this.$t('notifications.error'),
        position: 'bottom-right'
      });
      this.$router.push({ name: 'dashboard' });
    }

    this.$http
      .post('profile/confirm-change-email', {
        id
      })
      .then(
        (response) => {
          this.$notify({
            type: 'success',
            message: this.$t('notifications.confirmChangeEmail'),
            position: 'bottom-right'
          });
          this.loading = false;
          // todo: clear settings?
          this.$router.push({ name: 'dashboard' });
        },
        (err) => {
          this.$notify({
            type: 'error',
            message: this.$t('notifications.error'),
            position: 'bottom-right'
          });
          this.loading = false;
          this.$router.push({ name: 'dashboard' });
        }
      );
  }
};
</script>
